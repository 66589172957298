import React from 'react';
import './App.scss';

import image1 from './assets/byte-share/1.jpg';
import image2 from './assets/byte-share/2.jpg';
import image3 from './assets/byte-share/3.jpg';

function App() {
  return (
    <div className="App">
      <button className='ShareButton' onClick={handleShare(generateLinkShareData)}>Share Link</button>
      <button className='ShareButton' onClick={handleShare(generateByteShareData)}>Share Bytes</button>
      <button className='ShareButton' onClick={handleShare(generateMailShareData)}>Share Mail?</button>
      <button className='ShareButton' onClick={handleShare(generateSmsShareData)}>Share SMS?</button>
    </div>
  );
}

function isNativeShareAvailable() {
  return !!navigator.share && typeof navigator.share === 'function';
}

function alertNativeShareNotAvailable() {
  alert('navigator.share is not available');
}

function handleShare(generateShareDataFn: () => Promise<ShareData>) {
  return async () => {
    if (isNativeShareAvailable()) {
      try {
        await navigator.share(await generateShareDataFn());
      } catch (e: unknown) {
        alert(e);
      }
    } else {
      alertNativeShareNotAvailable();
    }
  };
}

async function generateMailShareData() {
  const shareData : ShareData = {
    title: "Check this out!",
    text: "Two new items",
    url: "mailto:dummy@gmail.com",
  };
  return shareData;
}

async function generateSmsShareData() {
  const shareData : ShareData = {
    title: "Check this out!",
    text: "Two new items",
    url: "sms:61410779100",
  };
  return shareData;
}

async function generateLinkShareData() {
  const shareData : ShareData = {
    title: "Check this out!",
    text: "Two new items",
    url: "https://photos.app.goo.gl/WUrMTpHYp41gPF8z9",
  };
  return shareData;
}

async function generateByteShareData() {
  const IMAGE_URLS = [
    image1, image2, image3
  ];

  const fileFromUrl = async (url : string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const fileName = encodeURIComponent(url.substring(url.lastIndexOf('/') + 1));
    return new File([blob], fileName, {type: blob.type});
  };

  const files = await Promise.all(IMAGE_URLS.map(fileFromUrl));

  const shareData : ShareData = {
    files,
  };
  return shareData;
}

export default App;
